import { ACTION_TYPE, ROUTE_KEY_TYPE } from '@/shared/utils/constants/common.ts';
import { ROUTE_NAMES } from '@/shared/utils/constants/router';

export const depositsRoutes = [
  {
    path: '/deposits',
    name: ROUTE_NAMES.deposits.main,
    meta: {
      keyName: ROUTE_KEY_TYPE.DEPOSITS,
      keyNameAction: ACTION_TYPE.VIEW
    },
    component: () =>
      import('@/sections/deposits/pages/deposits-list')
  },
  {
    path: '/deposits/open',
    name: ROUTE_NAMES.deposits.open,
    meta: {
      keyName: ROUTE_KEY_TYPE.DEPOSITS,
      keyNameAction: ACTION_TYPE.VIEW
    },
    component: () =>
      import('@/sections/deposits/pages/deposit-open')
  },
  {
    path: '/deposits/details',
    name: ROUTE_NAMES.deposits.details,
    meta: {
      keyName: ROUTE_KEY_TYPE.DEPOSITS,
      keyNameAction: ACTION_TYPE.VIEW
    },
    component: () =>
      import('@/sections/deposits/pages/deposit-details')
  },
  {
    path: '/deposits/replenishment',
    name: ROUTE_NAMES.deposits.replenishment,
    meta: {
      keyName: ROUTE_KEY_TYPE.DEPOSITS,
      keyNameAction: ACTION_TYPE.VIEW
    },
    component: () =>
      import('@/sections/deposits/pages/deposit-replenishment')
  }
];
